import { FC, lazy } from 'react';
import { CashierPermissionsEnum } from 'enums/accessTemplates';

import { IRoute } from 'pages/utils';
import BlankOutlet from 'components/common/BlankOutlet';
import Redirect from 'components/common/Redirect';

const routes: IRoute[] = [
  {
    path: '/partners',
    element: lazy(() => import('./Partners')),
    permissions: [CashierPermissionsEnum.SUPER_ADMIN],
  },
  {
    path: '/partners/:partnerId',
    element: lazy(() => import('./Partners/Partner')),
    permissions: [CashierPermissionsEnum.SUPER_ADMIN],
    routes: [
      {
        path: '',
        element: (() => <Redirect to="main" />) as FC,
      },
      { path: 'main', element: lazy(() => import('./Partners/Partner/Main')) },
      { path: 'payment-methods', element: lazy(() => import('./PaymentMethods/PartnerPaymentMethods')) },
    ],
  },
  {
    path: '/payment-methods',
    element: lazy(() => import('./PaymentMethods')),
    permissions: [CashierPermissionsEnum.SUPER_ADMIN],
  },
  {
    path: '/dashboard',
    element: lazy(() => import('./Dashboard')),
  },
  {
    path: '/users',
    element: lazy(() => import('./Users')),
    permissions: [
      CashierPermissionsEnum.SUPER_ADMIN,
      CashierPermissionsEnum.PARTNER_ADMIN,
      CashierPermissionsEnum.BET_SHOP_ADMIN,
      CashierPermissionsEnum.POS_ADMIN,
    ],
    routes: [
      { path: '', element: (() => <Redirect to="cashiers" />) as FC },
      {
        path: 'partner-admin',
        element: lazy(() => import('./Users/PartnerAdmin')),
        permissions: [CashierPermissionsEnum.SUPER_ADMIN],
      },
      {
        path: 'bet-shop-admin',
        element: lazy(() => import('./Users/BetShopAdmin')),
        permissions: [CashierPermissionsEnum.SUPER_ADMIN, CashierPermissionsEnum.PARTNER_ADMIN],
      },
      {
        path: 'pos-admin',
        element: lazy(() => import('./Users/PosAdmin')),
        permissions: [
          CashierPermissionsEnum.SUPER_ADMIN,
          CashierPermissionsEnum.PARTNER_ADMIN,
          CashierPermissionsEnum.BET_SHOP_ADMIN,
        ],
      },
      {
        path: 'cashiers',
        element: lazy(() => import('./Users/Cashier')),
        permissions: [
          CashierPermissionsEnum.SUPER_ADMIN,
          CashierPermissionsEnum.PARTNER_ADMIN,
          CashierPermissionsEnum.BET_SHOP_ADMIN,
          CashierPermissionsEnum.POS_ADMIN,
        ],
      },
    ],
  },
  {
    path: '/users/:userId',
    element: lazy(() => import('./Users/SinglePage')),
    routes: [
      {
        path: '',
        element: (() => <Redirect to="main" />) as FC,
      },
      { path: 'dashboard', element: lazy(() => import('./Users/SinglePage/Dashboard')) },
      { path: 'main', element: lazy(() => import('./Users/SinglePage/Main')) },
      { path: 'bet-shops', element: lazy(() => import('./Users/SinglePage/BetShops')) },
      { path: 'poses', element: lazy(() => import('./Users/SinglePage/Poses')) },
      { path: 'transactions', element: lazy(() => import('./Users/SinglePage/Transactions')) },
      { path: 'cashiers', element: lazy(() => import('./Users/SinglePage/Cashiers')) },
    ],
  },
  {
    path: '/bet-shop',
    element: lazy(() => import('./Shops/BetShop')),
    permissions: [
      CashierPermissionsEnum.SUPER_ADMIN,
      CashierPermissionsEnum.PARTNER_ADMIN,
      CashierPermissionsEnum.BET_SHOP_ADMIN,
    ],
  },
  {
    path: '/bet-shop/:shopId',
    element: lazy(() => import('./Shops/Shop')),
    permissions: [
      CashierPermissionsEnum.SUPER_ADMIN,
      CashierPermissionsEnum.PARTNER_ADMIN,
      CashierPermissionsEnum.BET_SHOP_ADMIN,
    ],
    routes: [
      {
        path: '',
        element: (() => <Redirect to="main" />) as FC,
      },
      { path: 'dashboard', element: lazy(() => import('./Shops/Shop/Dashboard')) },
      { path: 'main', element: lazy(() => import('./Shops/Shop/Main')) },
      { path: 'poses', element: lazy(() => import('./Shops/Shop/POSes')) },
      { path: 'from-parent', element: lazy(() => import('./Shops/Shop/Transactions/FromParent')) },
      { path: 'to-sub', element: lazy(() => import('./Shops/Shop/Transactions/ToPOSes')) },
      { path: 'wallets', element: lazy(() => import('./Shops/Shop/Wallets')) },
    ],
  },
  {
    path: '/pos',
    element: lazy(() => import('./Shops/POS')),
    permissions: [
      CashierPermissionsEnum.SUPER_ADMIN,
      CashierPermissionsEnum.PARTNER_ADMIN,
      CashierPermissionsEnum.BET_SHOP_ADMIN,
      CashierPermissionsEnum.POS_ADMIN,
    ],
  },
  {
    path: '/pos/:shopId',
    element: lazy(() => import('./Shops/Shop')),
    permissions: [
      CashierPermissionsEnum.SUPER_ADMIN,
      CashierPermissionsEnum.PARTNER_ADMIN,
      CashierPermissionsEnum.BET_SHOP_ADMIN,
      CashierPermissionsEnum.POS_ADMIN,
    ],
    routes: [
      {
        path: '',
        element: (() => <Redirect to="main" />) as FC,
      },
      { path: 'dashboard', element: lazy(() => import('./Shops/Shop/Dashboard')) },
      { path: 'main', element: lazy(() => import('./Shops/Shop/Main')) },
      { path: 'cashiers', element: lazy(() => import('./Shops/Shop/Cashiers')) },
      { path: 'from-bet-shops', element: lazy(() => import('./Shops/Shop/Transactions/FromBetshops')) },
      { path: 'to-players', element: lazy(() => import('./Shops/Shop/Transactions/ToPlayers')) },
      { path: 'wallets', element: lazy(() => import('./Shops/Shop/Wallets')) },
      { path: 'payment-methods', element: lazy(() => import('./Shops/Shop/PaymentMethods')) },
    ],
  },
  {
    path: '/payments',
    element: BlankOutlet,
    permissions: [
      CashierPermissionsEnum.SUPER_ADMIN,
      CashierPermissionsEnum.PARTNER_ADMIN,
      CashierPermissionsEnum.BET_SHOP_ADMIN,
      CashierPermissionsEnum.POS_ADMIN,
      CashierPermissionsEnum.USER,
    ],
    routes: [
      {
        path: 'deposits',
        element: lazy(() => import('./Payments/Deposits')),
        routes: [
          { path: '', element: (() => <Redirect to="in-review" />) as FC },
          {
            path: 'in-review',
            element: lazy(() => import('./Payments/Deposits/InReview')),
            permissions: [
              CashierPermissionsEnum.SUPER_ADMIN,
              CashierPermissionsEnum.PARTNER_ADMIN,
              CashierPermissionsEnum.BET_SHOP_ADMIN,
              CashierPermissionsEnum.POS_ADMIN,
              CashierPermissionsEnum.USER,
            ],
          },
          {
            path: 'completed',
            element: lazy(() => import('./Payments/Deposits/Completed')),
            permissions: [
              CashierPermissionsEnum.SUPER_ADMIN,
              CashierPermissionsEnum.PARTNER_ADMIN,
              CashierPermissionsEnum.BET_SHOP_ADMIN,
              CashierPermissionsEnum.POS_ADMIN,
              CashierPermissionsEnum.USER,
            ],
          },
        ],
        permissions: [
          CashierPermissionsEnum.SUPER_ADMIN,
          CashierPermissionsEnum.PARTNER_ADMIN,
          CashierPermissionsEnum.BET_SHOP_ADMIN,
          CashierPermissionsEnum.POS_ADMIN,
          CashierPermissionsEnum.USER,
        ],
      },
      {
        path: 'withdraws',
        element: lazy(() => import('./Payments/Withdraws')),
        permissions: [
          CashierPermissionsEnum.SUPER_ADMIN,
          CashierPermissionsEnum.PARTNER_ADMIN,
          CashierPermissionsEnum.BET_SHOP_ADMIN,
          CashierPermissionsEnum.POS_ADMIN,
          CashierPermissionsEnum.USER,
        ],
        routes: [
          { path: '', element: (() => <Redirect to="in-review" />) as FC },
          {
            path: 'in-review',
            element: lazy(() => import('./Payments/Withdraws/InReview')),
            permissions: [
              CashierPermissionsEnum.SUPER_ADMIN,
              CashierPermissionsEnum.PARTNER_ADMIN,
              CashierPermissionsEnum.BET_SHOP_ADMIN,
              CashierPermissionsEnum.POS_ADMIN,
              CashierPermissionsEnum.USER,
            ],
          },
          {
            path: 'completed',
            element: lazy(() => import('./Payments/Withdraws/Completed')),
            permissions: [
              CashierPermissionsEnum.SUPER_ADMIN,
              CashierPermissionsEnum.PARTNER_ADMIN,
              CashierPermissionsEnum.BET_SHOP_ADMIN,
              CashierPermissionsEnum.POS_ADMIN,
              CashierPermissionsEnum.USER,
            ],
          },
        ],
      },
      {
        path: 'vouchers',
        element: lazy(() => import('./Payments/Vouchers')),
        routes: [
          { path: '', element: (() => <Redirect to="pending" />) as FC },
          {
            path: 'pending',
            element: lazy(() => import('./Payments/Vouchers/Pending')),
            permissions: [
              CashierPermissionsEnum.SUPER_ADMIN,
              CashierPermissionsEnum.PARTNER_ADMIN,
              CashierPermissionsEnum.BET_SHOP_ADMIN,
              CashierPermissionsEnum.POS_ADMIN,
              CashierPermissionsEnum.USER,
            ],
          },
          {
            path: 'used',
            element: lazy(() => import('./Payments/Vouchers/Used')),
            permissions: [
              CashierPermissionsEnum.SUPER_ADMIN,
              CashierPermissionsEnum.PARTNER_ADMIN,
              CashierPermissionsEnum.BET_SHOP_ADMIN,
              CashierPermissionsEnum.POS_ADMIN,
              CashierPermissionsEnum.USER,
            ],
          },
        ],
        permissions: [
          CashierPermissionsEnum.SUPER_ADMIN,
          CashierPermissionsEnum.PARTNER_ADMIN,
          CashierPermissionsEnum.BET_SHOP_ADMIN,
          CashierPermissionsEnum.POS_ADMIN,
          CashierPermissionsEnum.USER,
        ],
      },
    ],
  },
  {
    path: '/reports',
    element: BlankOutlet,
    permissions: [
      CashierPermissionsEnum.SUPER_ADMIN,
      CashierPermissionsEnum.PARTNER_ADMIN,
      CashierPermissionsEnum.BET_SHOP_ADMIN,
      CashierPermissionsEnum.POS_ADMIN,
    ],
    routes: [
      { path: '', element: (() => <Redirect to="cashier" />) as FC },
      {
        path: 'bet-shop',
        element: lazy(() => import('./Reports/BetShop')),
        permissions: [
          CashierPermissionsEnum.SUPER_ADMIN,
          CashierPermissionsEnum.PARTNER_ADMIN,
          CashierPermissionsEnum.BET_SHOP_ADMIN,
        ],
      },
      {
        path: 'pos',
        element: lazy(() => import('./Reports/POS')),
        permissions: [
          CashierPermissionsEnum.SUPER_ADMIN,
          CashierPermissionsEnum.PARTNER_ADMIN,
          CashierPermissionsEnum.BET_SHOP_ADMIN,
          CashierPermissionsEnum.POS_ADMIN,
        ],
      },
      {
        path: 'cashier',
        element: lazy(() => import('./Reports/Cashier')),
        permissions: [
          CashierPermissionsEnum.SUPER_ADMIN,
          CashierPermissionsEnum.PARTNER_ADMIN,
          CashierPermissionsEnum.BET_SHOP_ADMIN,
          CashierPermissionsEnum.POS_ADMIN,
        ],
      },
    ],
  },
  {
    path: '/configurations',
    element: BlankOutlet,
    permissions: [CashierPermissionsEnum.SUPER_ADMIN, CashierPermissionsEnum.PARTNER_ADMIN],
    routes: [
      {
        path: '',
        element: (() => <Redirect to="bet-shop" />) as FC,
      },
      {
        path: 'bet-shop',
        element: lazy(() => import('./Configurations/index')),
        permissions: [CashierPermissionsEnum.SUPER_ADMIN, CashierPermissionsEnum.PARTNER_ADMIN],
      },
      {
        path: 'pos',
        element: lazy(() => import('./Configurations/index')),
        permissions: [CashierPermissionsEnum.SUPER_ADMIN, CashierPermissionsEnum.PARTNER_ADMIN],
      },
    ],
  },
  {
    path: '/settings',
    element: BlankOutlet,
    permissions: [CashierPermissionsEnum.SUPER_ADMIN, CashierPermissionsEnum.PARTNER_ADMIN],
    routes: [
      {
        path: '',
        element: (() => <Redirect to="currencies" />) as FC,
      },
      {
        path: 'currencies',
        element: lazy(() => import('./Currencies')),
        permissions: [CashierPermissionsEnum.SUPER_ADMIN, CashierPermissionsEnum.PARTNER_ADMIN],
      },
      {
        path: 'payment-methods/:id/payment-properties',
        element: lazy(() => import('./Configurations/index')),
        permissions: [CashierPermissionsEnum.SUPER_ADMIN, CashierPermissionsEnum.PARTNER_ADMIN],
      },
      {
        path: 'payment-methods',
        element: lazy(() => import('./PaymentMethods/SettingsPaymentMethods')),
        permissions: [CashierPermissionsEnum.SUPER_ADMIN, CashierPermissionsEnum.PARTNER_ADMIN],
      },
      {
        path: 'branding',
        element: lazy(() => import('./Branding')),
        permissions: [CashierPermissionsEnum.SUPER_ADMIN, CashierPermissionsEnum.PARTNER_ADMIN],
      },
    ],
  },
  {
    path: '/profile',
    element: lazy(() => import('./Users/ProfilePage')),
  },
  {
    path: '*',
    element: (() => <Redirect to="/dashboard" />) as FC,
  },
];

export default routes;
